<template>
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="27" height="27" rx="13.5" fill="#3DB1F2"/>
    <g clip-path="url(#clip0_4196_948)">
      <path d="M20.176 15.9372C20.9959 17.3292 20.5603 18.485 19.237 18.7466C18.7378 18.8486 18.1996 18.8375 17.675 18.7917C16.726 18.7155 16.3306 18.957 15.8733 19.8099C15.5794 20.3633 15.222 20.9075 14.7826 21.336C14.0318 22.0665 12.8992 21.8419 12.3587 20.8414C11.1312 18.581 10.8272 16.2489 11.6907 13.9236C12.2275 12.4763 13.2911 11.9023 14.8089 12.1613C16.7046 12.4861 18.2357 13.5482 19.5302 15.0592C19.7977 15.3854 20.0351 15.7467 20.176 15.9372Z" fill="white"/>
      <path d="M10.8059 9.7401C10.8988 10.228 11.0638 10.7112 11.0807 11.197C11.1028 11.9861 10.5577 12.4923 9.79927 12.4886C8.36225 12.4901 6.8277 10.7454 6.96292 9.26399C7.03777 8.41109 7.71978 7.92659 8.60127 8.10392C9.41167 8.25838 10.2467 8.91231 10.8059 9.7401Z" fill="white"/>
      <path d="M11.7551 9.25782C11.2949 8.31105 11.0987 7.44919 11.3948 6.56131C11.744 5.51734 12.7344 5.25488 13.6356 5.95244C14.5547 6.66693 15.056 8.11288 14.7773 9.25213C14.4629 10.5196 13.2997 10.8437 12.3205 9.92002C12.078 9.6968 11.8908 9.42433 11.7551 9.25782Z" fill="white"/>
      <path d="M9.67851 14.9076C9.73164 15.1928 9.89806 15.6932 9.90866 16.1825C9.92444 16.9752 9.37165 17.4677 8.61321 17.4641C7.18493 17.4516 5.67211 15.7307 5.78843 14.2599C5.85557 13.3934 6.52356 12.8988 7.41136 13.0725C8.35624 13.2591 9.01754 13.8851 9.67851 14.9076Z" fill="white"/>
      <path d="M16.6527 10.8941C16.1602 9.87548 15.964 9.01363 16.2538 8.1293C16.5943 7.09928 17.5668 6.8199 18.4627 7.49343C19.4746 8.25452 19.9867 9.91059 19.5574 11.0629C19.1986 12.0312 18.2755 12.3097 17.4216 11.6664C17.0916 11.4206 16.8188 11.0704 16.6527 10.8941Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_4196_948">
        <rect width="15" height="15" fill="white" transform="translate(3.28052 10.6606) rotate(-29.4726)"/>
      </clipPath>
    </defs>
  </svg>


</template>
<script>
export default {
  name: 'PawIcon',
}
</script>
<style lang="scss" scoped>
</style>