import PremiumLabelProperty from "@/components/Common/Labels/PremiumLabelProperty/PremiumLabelProperty";
import AddToFavourites from "@/components/Components/AddToFavourites/AddToFavourites";
import PawIcon from "@/components/Common/Icons/PawIcon.vue";

export default {
    name: 'PropertyAd',
    props: {
        adtype: {
            type: String,
            default: ''
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        id: {
            type: String,
        },
        images: {
            type: Array,
            default: []
        },
        city: {
            type: String,
            default: ''
        },
        district: {
            type: String,
            default: ''
        },
        amount: {
            type: String,
            default: ''
        },
        description: {
            type: String,
            default: ''
        },
        property: {
            type: Object,
            default: {}
        }
    },
    components: {
        PawIcon,
        PremiumLabelProperty,
        AddToFavourites
    },
    data() {
        return {
            winW: null
        }
    },
    computed: {
        titleFormatted() {
            const title = this.$props.title;
            let limit = 99;
            if (this.winW > 768) {
                if (this.winW < 1440) limit = 90;
                if (this.winW < 1300) limit = 80;
                if (this.winW < 1235) limit = 70;
                if (this.winW < 1154) limit = 60;
                if (this.winW < 935) limit = 50;
            } else {
                limit = 130;
            }
            return title.length > limit ? title.substring(0, limit) + "..." : title;
        }
    },
    mounted() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.winW = window.innerWidth;
        }
    }
}